<template>
  <div class="info">

    <el-row class="info-title" type="flex" justify="center">
      <el-col :span="16" class="info-title-flex">
        <span class="title-name">产品列表({{pagination.total}})</span>
        <el-input placeholder="输入产品名称" class="title-search" v-product="pagination.name"></el-input>
        <span class="label">排序:
            <el-popover placement="bottom" trigger="click">
            <a slot="content">
              <p>创建时间升序</p>
              <p>创建时间降序</p>
            </a>
            <el-button type="text">
              默认排序
            </el-button>
          </el-popover>
          </span>
      </el-col>
      <el-col :span="8" style="text-align: right" class="info-title-remark">

        <el-button type="primary" >
          <i class="el-icon-plus"/>
          创建模型
        </el-button>
      </el-col>
    </el-row>

    <el-row class="info-item-container">
      <el-col :span="6" v-for="(item, idx) in products" :key="idx" class="item-container">
        <div class="info-item">
          <p class="info-item-name">{{item.name}}</p>
          <p class="info-item-content">
            <span class="label">产品编号:</span>
            <span class="content">{{item.uuid}}</span>
          </p>
          <p class="info-item-content">
            <span class="label">产品备注:</span>
            <span class="content">{{item.remark}}</span>
          </p>
          <p class="info-item-content">
            <span class="label">已注册设备:</span>
            <span class="content">{{item.devices | I1000}}</span>
          </p>
          <p class="info-item-content">
            <span class="label">创建时间:</span>
            <span class="content">{{new Date(item.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</span>
          </p>

          <p class="info-item-opr">
            <span @click="handleProductDetail(item)">产品详情</span>
            <span @click="handleProductSecret(item)">查看密钥</span>
          </p>
        </div>
      </el-col>
    </el-row>

  </div>

</template>

<script>

  import {productUnion} from '@/api/system/product'

  export default {
    name: 'system-endpoint-product',
    components: {},
    mounted: function () {
      this.loadProduct()
    },
    data: function () {
      return {
        showMore: false,
        showMoreVisible: true,
        visible: false,
        total: 4,
        pagination: {
          name: '',
          total: 0
        },
        materialTotal: 5,
        products: [],
        material: {},
      }
    },
    methods: {
      handleShowMore() {
        let m = this.materialSorts.findIndex(m => m.uuid === '4')
        if (m === -1) {
          this.materialSorts.push({
            uuid: '4',
            total: 1000,
            use: 800,
            rest: 100,
            waste: 100,
            quality: 5,
            store: 15,
            name: '纯净水',
            unit: 'ml',
            alarm: 10
          })
        } else {
          this.materialSorts.splice(m, 1)
        }
        this.showMore = !this.showMore
      },
      handleProductSecret() {
        this.material = {uuid: '', name: '', sortName: '', materialSortUuid: ''}
        this.visible = true
      },
      handleProductDetail(material) {
        this.$router.push({name: 'system.endpoint.product.detail', params: {uuid: material.uuid, name: material.name}})
      },

      loadProduct() {
        productUnion(this.pagination).then(res => {
          this.products = res.data.list || []
          this.pagination.total = parseInt(res.data.total)
        })
      },
      onClose() {
        this.visible = false
      }
    },
  }
</script>

<style lang="scss" scoped>

  .info {
    padding: $middle-space;
    &-title {
      @include font-medium();
      font-weight: 500;
      margin: $middle-space;
      margin-bottom: 0;
      &-flex {
        display: flex;
        align-content: center;
        justify-items: center;
        align-items: center;

        .title-search {
          width: 200px;
          margin-left: $middle-space;
        }

        .label {
          @include font-little();
          padding-right: $small-space;
          padding-left: $small-space;
          font-weight: normal;
          text-align: right;
        }
      }
    }


    &-item-container {
      margin: $middle-space;
    }

    &-item {
      padding: $small-space;
      padding-left: $middle-space;
      border: 1px solid $content-border-color;
      border-radius: 4px;
      margin: $small-space;
      position: relative;

      &-name {
        color: $theme-color;

        @include font-medium();
        margin-bottom: $middle-space;

        span {
          color: $tip-text-color;
          cursor: pointer;
        }
      }

      &-name:hover {
        color: $theme-dark-2;
      }

      &-content {
        margin-right: $small-space;
        @include font-little();
      }

      &-opr {
        margin: $small-space 0;

        span {
          display: inline-block;
          width: 50%;
          text-align: center;
          cursor: pointer;
        }


        span:hover {
          color: $theme-color;
        }

        .opr-border {
          border-left: 1px solid $content-border-color;
        }
      }
    }

    &-item:hover{
      box-shadow: 0 0 8px 2px rgba(0, 0, 0, .08);
      cursor: pointer;
    }

    &-material-opr {
      height: 100%;
      width: 100%;
      position: relative;

      &-footer {
        position: absolute;
        width: 100%;
        border-top: 1px solid $content-border-color;
        padding-top: $middle-space;
        bottom: -$middle-space;
        left: 0;

        .delete {
          color: $brand-danger;
        }

        .save {
          float: right;
        }
      }

      &-footer:after {
        clear: both;
        display: block;
        content: " ";
      }

    }


  }

</style>
