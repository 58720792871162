import request from '@/utils/request';

export function productUnion(params) {
  return request({
    url: `/v1/equipment/product/union`,
    method: 'GET',
    params
  })
}

export function productList(params) {
  return request({
    url: `/v1/equipment/product/list`,
    method: 'GET',
    params
  })
}

export function productDetail(params) {
  return request({
    url: `/v1/equipment/product/detail`,
    method: 'GET',
    params
  })
}

export function productVersionUnion(params) {
  return request({
    url: `/v1/equipment/product/versionUnion`,
    method: 'GET',
    params
  })
}


